import React from 'react';
import '../css/card.css';
import ladiesSangeet from '.././images/ladies-sangeet.png'

function Timetable() {

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <section id='Timetable' className='md:col-span-1 Timetable w-1/2'>
                    <tabel>
                        <tbody>
                            <tr>
                                <td><img src={ladiesSangeet} alt='' width={'50px'} /> </td>
                                <td></td>
                                <td>Ladies sangeet <br />
                                    <span className='text-sm'>11-03-2024 (05:15 PM)</span>
                                </td>
                            </tr>
                            <tr>
                                <td><img src='' alt='' /> </td>
                                <td></td>
                                <td>Lunch <br />
                                    <span className='text-sm'>12-03-2024 (10:15 AM)</span>
                                </td>
                            </tr>
                        </tbody>
                    </tabel>
                    <div className="bg-amber-200 relative">
                        <div className="absolute left-1/2 -ml-0.5 w-0.5 h860px bg-gray-600"> </div>
                    </div>
                </section>

                <section id='eventDate' className='md:col-span-1 flex w-5/12 items-center text-center text-3xl font-bold mb-5'>
                    <div className="flex-none w-4/12 text-right items-center">Tuesday</div>
                    <div className="justify-center">
                        <div className="text-2xl">10:15</div>
                        <div className="w-full">
                            <span className="border-l-2 border-r-2 px-5 mx-5 my-10 text-6xl">12</span>
                        </div>
                        <div>2024</div>
                    </div>
                    <div className="flex-none w-5/12 text-left">March</div>
                </section>
            </div >
        </>
    );
}
export default Timetable;