import React from 'react';
import Sakura from '../components/Sakura';
import EventTitle from '../components/EventTitle';
import Timetable from '../components/Timetable';
import Calendar from '../components/Calendar';
import Venue from '../components/Venue';
import PreWedding from '../components/PreWedding';
import Menu from '../components/Menu';
import CountdownTimer from '../components/CountdownTimer';
import MenuBar from '../components/MenuBar';


const targetDate = '2024-03-12T10:15:00';
function Card() {
    return (
        <>
            <MenuBar />
            <Sakura />
            <EventTitle className="md:col-span-1" />
            <CountdownTimer targetDate={targetDate} />
            <PreWedding />
            <Timetable />
            <Calendar />
            <Venue />
            <Menu />
        </>
    );
}
export default Card;
