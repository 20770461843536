import React from 'react';
import { Link } from 'react-router-dom';

function MenuBar() {
    return (
        <>
            <header className="flex items-center justify-center">
                <section className="z-50 fixed top-20 bg-white border-6 border-white rounded-full shadow-md">
                    <nav className="flex header-main p-5">
                        <Link href="/#meetus" className="menuBar-link" activeClassName="active">Meet Us</Link>
                        <Link href="/#venue" className="menuBar-link" activeClassName="active">Venue</Link>
                        <Link href="/#events" className="menuBar-link" activeClassName="active">Events</Link>
                        <Link href="/#pre_wed" className="menuBar-link" activeClassName="active">Pre Wed</Link>
                    </nav>
                </section>
            </header>
        </>
    );
}

export default MenuBar;
