import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Menu() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,   
    };

    return (
        <div className="container mx-auto my-52 ">
            <Slider {...settings}>
                <div className="text-center px-2"><div className='border-2'><img src={`images/preWedding/1.jpg`} alt="gallery-photo" className='w-full h-full' /></div></div>
                <div className="text-center px-2"><div className='border-2'><img src={`images/preWedding/2.jpg`} alt="gallery-photo" className='w-full h-full' /></div></div>
                <div className="text-center px-2"><div className='border-2'><img src={`images/preWedding/3.jpg`} alt="gallery-photo" className='w-full h-full' /></div></div>
                {/* <div className="text-center px-2"><div className='border-2'><img src={`images/preWedding/4.jpg`} alt="gallery-photo" className='w-full h-full' /></div></div> */}
                <div className="text-center px-2"><div className='border-2'><img src={`images/preWedding/5.jpg`} alt="gallery-photo" className='w-full h-full' /></div></div>
            </Slider>
        </div>
    );
};

export default Menu;