import React, { useState, useEffect } from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import '../css/wave.css';

const Calendar = () => {
    const currentDate = new Date();
    const targetDate = new Date(2024, 2, 12); // March 12, 2024

    const startOfMarch = startOfMonth(targetDate);
    const daysInMarch = eachDayOfInterval({ start: startOfMarch, end: endOfMonth(targetDate) });


    return (
        <section id='calendar'>
            <div className="container mx-auto mt-10">
                <div className="grid grid-cols-7 gap-4">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                        <div key={index} className="text-center font-bold text-black">
                            {day}
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-7 gap-4">
                    {Array(startOfMarch.getDay()).fill(null).map((_, index) => (
                        <div key={`empty-${index}`} className="text-center text-gray-400"></div>
                    ))}
                    {daysInMarch.map((day) => (

                        <div key={day.getDate()} className={`text-center ${day.getDate() === 12 ? 'bg-red-500' : ''}`}>
                            {day.getDate()}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Calendar;
