import React, { useEffect } from 'react';
import '../css/heart.css';

const Heart = () => {
  const heartRain = () => {
    const heart = document.querySelector('.heart');
    const drop = document.createElement('div');
    drop.classList.add('drop');
    heart.appendChild(drop);

    const left = Math.floor(Math.random() * 300);
    const duration = Math.random() * 0.5;

    drop.style.left = `${left}px`;
    drop.style.animationDuration = `${1 + duration}s`;

    setTimeout(() => {
      heart.removeChild(drop);
    }, 5000);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      heartRain();
    }, 800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id='Heart'>
      <div className='heart'></div>
      <div className='drop'></div>
    </section>
  );
};

export default Heart;
