import React from 'react';
import '../css/card.css';


const getRandomShapeClass = () => {
    const shapes = ['rounded-circle', 'rounded-triangle', 'rounded-rectangle'];
    const randomIndex = Math.floor(Math.random() * shapes.length);
    return shapes[randomIndex];
};

const getRandomRotationClass = () => {
    const rotations = ['rotate-0', 'rotate-[25deg]', 'rotate-[17deg]', 'rotate-[-25deg]'];
    const randomIndex = Math.floor(Math.random() * rotations.length);
    return rotations[randomIndex];
};

function PreWedding() {

    return (
        <section id='PreWedding' className='PreWedding flex py-32'>
            <div className="grid grid-cols-2 gap-10 sm:grid-cols-3 md:grid-cols-4">
                {[1, 2, 3, 4, 5, 6].map((index) => (
                    <div key={index}>
                        <img
                            className={`${getRandomShapeClass()} ${getRandomRotationClass()} h-40 w-full max-w-full rounded-lg object-cover object-center`}
                            src={`images/PreWedding${index}.jpeg`}
                            alt="gallery-photo"
                        />

                    </div>
                ))}
            </div>
        </section>
    );
}
export default PreWedding;