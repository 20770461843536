import React, { useState, useEffect } from 'react'
import Heart from '../components/Heart';

const EventTitle = () => {
    const texts = ['&', 'And', 'Weds'];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 4000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const currentText = texts[currentTextIndex];

    return (

        <section id='eventTitle' className='grid grid-cols-1 md:grid-cols-3 items-center text-center text-3xl font-bold mb-5'>

            <div className="md:col-span-1">
                <img src={`images/preWedding/1.jpg`} alt="gallery-photo" className='w-full' />
            </div>

            <div className="md:col-span-1">
                <div>Ashish</div>
                <div className='m-7 flex items-center '>
                    <div className='separator'>

                        <div className='wave px-5 rounded-full bg-green-300 text-green-800'>
                            <div>
                                {[...currentText].map((char, index) => (
                                    <span
                                        key={index}
                                        style={{ '--i': index }}
                                    >
                                        {char}
                                    </span>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                <div>Bharti</div>
            </div>

            <Heart className="md:col-span-1" />

        </section>
    )
}

export default EventTitle