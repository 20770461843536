// CountdownTimer.js
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const targetTime = new Date(targetDate).getTime();
        const difference = targetTime - now;

        if (difference <= 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section id='CountdownTimer'>
            <div className="flex justify-around text-xl font-bold">
                <div className="text-center bg-gray-300 text-black p-5 md:p-5 lg:p-5 xl:p-5 rounded-2xl">
                    <span className="block text-2xl">{timeLeft.days}</span>
                    <p>days</p>
                </div>
                <div className="text-center bg-gray-300 text-black p-5 md:p-5 lg:p-5 xl:p-5 rounded-2xl">
                    <span className="block text-2xl">{timeLeft.hours}</span>
                    <p>hours</p>
                </div>
                <div className="text-center bg-gray-300 text-black p-5 md:p-5 lg:p-5 xl:p-5 rounded-2xl">
                    <span className="block text-2xl">{timeLeft.minutes}</span>
                    <p>minutes</p>
                </div>
                <div className="text-center bg-gray-300 text-black p-5 md:p-5 lg:p-5 xl:p-5 rounded-2xl">
                    <span className="block text-2xl">{timeLeft.seconds}</span>
                    <p>seconds</p>
                </div>
            </div>
        </section>
    );
};

export default CountdownTimer;
