import "../css/sakura.css"
import React from 'react';

const Sakura = () => {

    return (
        <section id='sakura' className='sakura'>
            <div className="set">
                <div><img src={`images/sakura1.png`} /></div>
                {/* <div><img src={`images/sakura2.png`} /></div> */}
                <div><img src={`images/sakura3.png`} /></div>
                {/* <div><img src={`images/sakura4.png`} /></div> */}
                <div><img src={`images/sakura5.png`} /></div>
                {/* <div><img src={`images/sakura6.png`} /></div> */}
                <div><img src={`images/sakura7.png`} /></div>
                {/* <div><img src={`images/sakura8.png`} /></div> */}
                <div><img src={`images/sakura9.png`} /></div>
                {/* <div><img src={`images/sakura10.png`} /></div> */}
                <div><img src={`images/sakura11.png`} /></div>
                {/* <div><img src={`images/sakura12.png`} /></div> */}
                <div><img src={`images/sakura13.png`} /></div>
                {/* <div><img src={`images/sakura14.png`} /></div> */}
                <div><img src={`images/sakura15.png`} /></div>
                {/* <div><img src={`images/sakura16.png`} /></div> */}
            </div>
            <div className="set set2">
                {/* <div><img src={`images/sakura1.png`} /></div> */}
                <div><img src={`images/sakura2.png`} /></div>
                {/* <div><img src={`images/sakura3.png`} /></div> */}
                <div><img src={`images/sakura4.png`} /></div>
                {/* <div><img src={`images/sakura5.png`} /></div> */}
                <div><img src={`images/sakura6.png`} /></div>
                {/* <div><img src={`images/sakura7.png`} /></div> */}
                <div><img src={`images/sakura8.png`} /></div>
                {/* <div><img src={`images/sakura9.png`} /></div> */}
                <div><img src={`images/sakura10.png`} /></div>
                {/* <div><img src={`images/sakura11.png`} /></div> */}
                <div><img src={`images/sakura12.png`} /></div>
                {/* <div><img src={`images/sakura13.png`} /></div> */}
                <div><img src={`images/sakura14.png`} /></div>
                {/* <div><img src={`images/sakura15.png`} /></div> */}
                <div><img src={`images/sakura16.png`} /></div>
            </div>
            <div className="set set3">
                <div><img src={`images/sakura1.png`} /></div>
                <div><img src={`images/sakura2.png`} /></div>
                {/* <div><img src={`images/sakura3.png`} /></div> */}
                {/* <div><img src={`images/sakura4.png`} /></div> */}
                <div><img src={`images/sakura5.png`} /></div>
                <div><img src={`images/sakura6.png`} /></div>
                {/* <div><img src={`images/sakura7.png`} /></div> */}
                {/* <div><img src={`images/sakura8.png`} /></div> */}
                <div><img src={`images/sakura9.png`} /></div>
                <div><img src={`images/sakura10.png`} /></div>
                {/* <div><img src={`images/sakura11.png`} /></div> */}
                {/* <div><img src={`images/sakura12.png`} /></div> */}
                <div><img src={`images/sakura13.png`} /></div>
                <div><img src={`images/sakura14.png`} /></div>
                {/* <div><img src={`images/sakura15.png`} /></div> */}
                {/* <div><img src={`images/sakura16.png`} /></div> */}
            </div>
        </section>
    )
}

export default Sakura