import React from 'react';

function Venue() {

    return (
        <section id='Venue' className="grid grid-cols-1 md:grid-cols-2  gap-4 py-11 ">
            <div className="md:col-span-1 flex justify-center content-center text-center items-center">Kumhar-Khati Dharamshala, Barwa <br></br>Siwani (Bhiwani)</div>

            <div className="md:col-span-1 flex justify-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d218.18128708316956!2d75.60664800443038!3d28.960672784847123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707146689542!5m2!1sen!2sin"
                    width="300"
                    height="300"
                    className='rounded-full'
                    style={{ boxShadow: "8px 8px 16px #4f5f4a, -8px -8px 16px #7d8c78" }}
                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    );
}
export default Venue;
